import React, { FC } from "react";

import { graphql } from "gatsby";
import CaseStudyMain from "../components/CaseStudyUiComponents/CaseStudyMain";
import Layout from "../components/Layout/Layout";

type Props = {
  location: any;
  data: any;
  pageContext: any;
};

const TagsCaseStudy: FC<Props> = ({ location, data, pageContext }) => {
  return (
    <Layout location="/blog">
      <CaseStudyMain data={data} pageContext={pageContext} />
    </Layout>
  );
};

export default TagsCaseStudy;

export const query = graphql`
  query AllSanityTagsCaseStudyQuery($tag: String!) {
    allSanityCaseStudy(
      filter: { tags: { elemMatch: { name: { eq: $tag } } } }
      sort: { order: DESC, fields: _createdAt }
    ) {
      edges {
        node {
          _rawMainImage(resolveReferences: { maxDepth: 20 })
          _rawTestimonialImage
          _rawSolutionImage
          _rawChallengeImage
          _rawBenefitsImage
          altBenefitsImage
          altChallengeImage
          altSolutionImage
          altTestimonialImage
          altMainImage
          id
          title
          titleDE
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
          altMainImage
          caseStudyDescriptionDe {
            children {
              text
            }
          }
          caseStudyDescription {
            children {
              text
            }
          }
        }
      }
    }
    allSanityCaseStudyTag {
      edges {
        node {
          name
        }
      }
    }
  }
`;
